<template>
    <div class="video-list-bg">
        <div class="head-boc">
          <span class="iconfont icon-arrow-left-bold" @click="onClickLeft"></span>
         <van-search class="head-search" v-model="keywords" placeholder="请输入搜索关键词" />
        </div>
        <div class="list-item" v-for="item in search(keywords)" :key="item.id" @click="seeImg(item.split('|')[1])" >
            <div class="list-icon iconfont icon-pdf_fill"></div>
            <div class="list-right">
                <div class="list-text">{{item.split("|")[0]}}</div>
                <div class="list-btn">查看</div>
            </div>
        </div>


        <van-popup  v-model="show" round :close-on-click-overlay=false>
          <div  class="serve-box">
            <img style="width:100%;" :src="dialogLmgUrl" />
            <div @click="show = false" class="close-btn iconfont icon-_cuowu">
            </div>
          </div>
      </van-popup>
		  <van-empty  image="network" v-show="search(keywords).length<1?true:false" description="暂无搜索内容" />
    </div>
    
</template>
<script>
export default {
  data() {
    return {
      show:false,
      keywords:"",
      dialogLmgUrl:'',
      imgList: []
    };
  },
  methods: {
      seeImg (url) {
        console.log(url);
        this.show=true;
        this.dialogLmgUrl= require('../../assets/img/video/multiple'+url);
      },
      search(keywords) { // 根据关键字，进行数据的搜索
        return this.imgList.filter(item => {
          if (item.split("|")[0].includes(keywords)) {
          return item
          }
        })
      },
      onClickLeft() {
        this.$router.push('/video') ;
      }
  },
  created() {    
	  this.imgList = this.$route.params.certificateId.replace(/^\"|\"$/g, '').split(/,/);
  }
};

</script>
<style lang="less" scoped>
    .head-boc {
      	display: -webkit-flex;
      	display: flex;
        -webkit-align-items: center;
	      align-items: center;
        padding: 0 15px;
        .iconfont {
          font-size: 40px;
        }
        .head-search {
            -webkit-flex: 1;
            flex: 1;
        }
    }
    .video-list-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        background-color: #fff;
    }
    .list-item  {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 20px;
        .list-icon {
            font-size: 40px;
            color: #FF743F;
            margin-right: 20px;
        }
        .list-right {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            font-size: 28px;
            -webkit-flex: 1;
            flex: 1;
            border-bottom: 1px solid #EFEFEF;
            padding: 20px 0;
            .list-text {
                 -webkit-flex: 1;
                 flex: 1;
                 color: #333;
            }
            .list-btn {
                width: 100px;
                height: 60px;
                line-height: 60px;
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                border-radius: 25px;
                text-align: center;
                color: #666666;
            }
        }
    }
    .van-popup--center {
        top: 50%;
        right: 10%;
        left: 10%;
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
        overflow: visible;
        background-color: rgba(0, 0, 0, 0);
    }
    .serve-box {
        padding: 15px 5px;
        text-align: center;
        position: relative;
        background-color: #fff;
      
        img {
            border-radius: 10px;
        }
        .close-btn {
          position: absolute;
          bottom: -70px;
          color: #fff;
          left: 50%;
          margin-left: -15px;
          font-size: 56px;
          border-radius: 50%;
        }
    }
</style>